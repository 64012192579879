<template>
	<div class="animated fadeIn">
		<div class="card" no-body>
			<div class="header">
				Welcome to Zapa for Business
			</div>
			<div>
				<div>
					<div>
						Thank you joining Zapa for Business, please check your email to verify your account and get started!
					</div>
				</div>
				<div>
					<div>

					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {VueController} from '../service/support/vuex-controller';

	export default VueController("Welcome", {

	});
</script>
